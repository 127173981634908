/* eslint-disable */
import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnIcon,
  TrophyFillIcon,
  MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Swap'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: t('Pools'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/liquidity',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: 'Earn',
        href: 'https://richpst.com/staking/',
        icon: EarnIcon,
        type: DropdownMenuItemType.EXTERNAL_LINK,
        showItemsOnMobile: false,
        items: []
      },
      {
        label: "",
        href: "#",
        icon: MoreIcon,
        items: [
          {
            label: "Marketplace",
            href: "https://pstmarket.com",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "PSTCoin",
            href: "https://pstcoin.com",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Chart",
            href: "https://www.dextools.io/app/en/bnb/pair-explorer/0x7d1a629ecff43f42222fa63bdbc07f3e5a2b9b73?t=1723263020771",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Document",
            href: "https://docs.pstcoin.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          }
        ],
      }
    ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config
